
import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, PackageService, OrderService, FamilyService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "../../../config/index";
const showRightsCode = globalConfig.showRightsCode;

@Component
export default class GoodsDetail extends Vue {
  /**
   * 商品数量
   * @private
   * @returns number
   */
  private goodsNum: number = 1;

  /**
   * 商品详情id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 权益id
   * @private
   * @returns string
   */
  private get rightsId(): string {
    return (this.$route.query && (this.$route.query.rightsId as string)) || "";
  }

  /**
   * 跳转订单明细
   * @private
   * @returns void
   */
  private onActionOrderDetail(): void {
    this.$router.push({ name: "goods-order-detail", query: { id: this.id } });
  }

  /**
   * 跳转首页
   * @private
   * @returns void
   */
  private onHome(): void {
    this.$router.push({ name: "home" });
  }

  /**
   * 获取数据
   * @private
   * @returns void
   */
  private get appid(): string {
    return localStorage.getItem("appid") || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 跳转健康首页
   * @private
   * @returns void
   */
  private onHealthHome(): void {
    this.$router.push({ name: "health-product-home" });
  }

  /**
   * 是否显示权益相关的
   * @private
   * @returns void
   */
  private get showRights(): any {
    return showRightsCode.includes(this.appid);
  }
}
