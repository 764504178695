
import { Component, Vue } from "vue-property-decorator";
import { HealthProductService, OrganizationService, WechatService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";

@Component
export default class GoodsDetail extends Vue {
  /**
   * 数据模型
   * @private
   * @returns any
   */
  protected dataModel: any = {
    bannerPicts: "",
    bannerPictsUrls: [],
    id: null,
    infoPicts: "",
    infoPictsUrls: [],
    introduce: "",
    isNeedAppointment: false,
    mainPict: "",
    mainPictUrl: "",
    marketPrice: null,
    name: "",
    price: null,
    remark: "",
    supplierId: null,
    supplierName: "",
  };

  /**
   * 选中tab值
   * @private
   * @returns string
   */
  private active: string = "";

  /**
   * 商品详情id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 权益id
   * @private
   * @returns string
   */
  private get rightsId(): string {
    return (this.$route.query && (this.$route.query.rightsId as string)) || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getHealthProductDetail(this.id);
  }

  /**
   * 点击购买
   * @protected
   * @returns void
   */
  protected onBuy() {
    this.$router.push({ name: "goods-order-submit", query: { id: this.id, rightsId: this.rightsId } });
  }

  /**
   * 获取健康产品详情
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getHealthProductDetail(id: string): Promise<void> {
    try {
      let { content: result } = await HealthProductService.instance.getHealthProductDetail(id);
      if (result.data) {
        this.dataModel = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
